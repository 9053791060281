<template>
  <div class="height-100 backg">
    <loading v-if="loading" backcolor="#fff"></loading>
    <div v-else>
      <head-child class="head">
        <template #left>
          <div class="flex jcc t-a-c" v-if="boo2" @click="Switch">
            <!-- <van-loading type="spinner" v-if="boo" /> -->
            <div class="ellipsis mr-5">
              {{ address }}
            </div>
            <div @click="Switch">
              <i class="iconfont icon-iconfontarrows1"></i>
            </div>
          </div>
          <div class="iconfont icon-zuojiantou" v-else @click="goback"></div>
        </template>
        <template #middle>
          <van-search v-model="value" placeholder="搜索商品" shape="round" @focus="onSearch" background="#00000000"
            @search="seach" />
        </template>
        <template #right>
          <div @click="seach" v-if="boo3">搜索</div>
          <div @click="delSeach" v-else>取消</div>
        </template>
      </head-child>
      <!-- <div style="display: flex;height: 3rem;">
        <div class="flex jcc t-a-c" style="color: #FFF;" v-if="boo2">
          <div class="ellipsis" style="margin-left: 1rem;">
            {{ address }}
          </div>
          <div @click="Switch" style="margin-left: 0.2rem;">
            <i class="iconfont icon-iconfontarrows1"></i>
          </div>
        </div>
        <div v-else
          style="height: 3rem; line-height: 3rem; width: 3rem; color: #FFF;align-items: center;text-align: center;"
          @click="goback">
          <div class="iconfont icon-zuojiantou"></div>
        </div>
        <van-search v-model="searchValue" placeholder="搜索商品" style="width: 100%;" shape="round" background="#00000000"
          @search="onSearch">
        </van-search>
      </div> -->

      <div v-if="boo2">
        <div v-if="movies.length > 0" style="overflow: scroll;height: 100vh;">
          <div
            style="color: #FFF;font-size: 1rem;padding: 0.3rem;align-items: center;margin-left: 0.6rem;margin-right: 0.2rem;">
            <img style="margin-right: 0.5rem;margin-bottom: -0.2rem; width: 1.2rem;"
              src="../../assets/images/movie1.svg">
            影片速递
          </div>
          <div style="margin-left: 0.6rem;margin-right: 0.6rem;margin-bottom: 6rem;">
            <van-swipe s @change="onChange" :autoplay="5000" indicator-color="white"
              style="height: 10rem;border-radius: 0.5rem;margin: 0.5rem;">
              <van-swipe-item v-for="(item, index) in headData" :key="index">
                <van-image fit="fill" :src="item.showImgId.showImg" alt="" class="img" />
              </van-swipe-item>
              <template #indicator>
                <ul class="indicators">
                  <li class="indicator" v-for="(item, index) in headData" :key="index"
                    :class="{ active: checkActive(index) }">
                  </li>
                </ul>
              </template>
            </van-swipe>
            <div style="color: #FFF;font-size: 1rem;padding: 0.3rem; display: flex;">
              <img style="margin-right: 0.5rem;width: 1.2rem;" src="../../assets/images/movie2.svg">
              热映榜单
              <div @click="goShop" v-if="showColor">
                <img style="margin-right: 0.2rem;margin-bottom: -0.3rem;width: 1.4rem; margin-left: 0.5rem;"
                  src="../../assets/images/movie-shop.png">
                缤纷商城
              </div>
            </div>
            <movit-item :list="movies" :shou-top=true>
            </movit-item>
          </div>
        </div>
        <div class="empt" v-else>
        </div>
        <div class="buttomBt">
          <div class="more" @click="goMore">
            查看更多
          </div>
        </div>
      </div>
      <seachview v-else ref="seach" :value="value" @value="seachvalue"></seachview>
    </div>

  </div>
</template>
<script>
import MovitItem from "../../components/movie/MovieItem.vue";
import Seachview from "../../components/movie/Seachview.vue";
import HeadChild from "../../components/HeadChild";
export default {
  name: 'MoviesHome',
  data() {
    return {
      scrollTop: null,
      current: 0,
      boo: false,
      boo3: true,
      loading: true,
      address: '',
      value: '',
      headData: [],
      movies: [],
      form: {
        cityCode: ''
      },
      searchValue: '',
      cardId: '',//福利卡id
      boo2: true,
      upgradeFlag: null,
      showColor: false,
    }
  },
  created() { },
  components: { MovitItem, Seachview, HeadChild },
  watch: {
    // 输入框的值
    value(val, oldval) {
      if (oldval !== "") {
        this.boo3 = true;
      }
    },
  },
  methods: {
    seachvalue(val) {
      this.value = val;
    },
    goShop() {
      this.$router.push("/MovieClassification");
    },
    goDetail(val) {
      this.$router.push({
        path: "/MovieDetail",
        query: {
          showbutton: 1,
          item: JSON.stringify(val),
        },
      });
    },
    // 取消
    delSeach() {
      this.value = null;
      this.boo3 = true;
      this.$refs.seach.history = true;
      this.$refs.seach.getHistroy();
    },
    // 输入框获取焦点
    seach1() {
      this.boo2 = false;
    },
    // 返回
    goback() {
      this.boo2 = true;
    },
    // 位置选择
    Switch() {
      this.$router.push("SelectAdderss");
    },
    seach() {
      this.$refs.seach.seach(this.value);
      this.boo3 = false;
    },
    // 定位
    onSearch() {
      this.boo2 = false;
    },
    goMore() {
      this.$router.push({
        path: "/Movies",
      });
    },
    checkActive(index) {
      return index === this.current;// current为监听的轮播index
    },
    onChange(index) {
      this.current = index;
    },
    queryHotMoviesByCity() {
      this.$api
        .queryHotMoviesByCity(this.cardId, this.form.cityCode, '')
        .then((res) => {
          if (res.code == 1) {
            this.loading = false
            this.$Toast(res.msg);
            return;
          }
          this.movies = res.data

          this.loading = false
        })
        .catch((err) => {
          this.$Toast(err.msg);
          this.loading = false
        });
    },
    queryShowColorfulMall() {
      this.$api
        .queryShowColorfulMall(this.upgradeFlag, this.cardId)
        .then((res) => {
          if (res.code == 0) {
            this.showColor = res.data
          }
        })
        .catch((err) => {

        });
    },
    getBanner() {
      let form = {
        cardId: this.cardId,
        cityCode: this.form.cityCode,
        upgradeFlag: this.upgradeFlag,
        bathId: localStorage.getItem("bathId"),
        hotType: "A",
        showPositionCode: "2"
      }
      this.$api
        .getQueryRotation(form)
        .then((res) => {
          this.headData = res.data
          this.loading = false;
        })
        .catch((err) => {
        });
    },
    initView() {
      this.form.cityCode = localStorage.getItem("cityCode");
      this.cardId = localStorage.getItem("cardId");
      this.address = localStorage.getItem("address");
      this.upgradeFlag = localStorage.getItem("upgradeFlag")
      this.queryHotMoviesByCity()
      this.getBanner()
      this.queryShowColorfulMall()
    }
  },

  beforeRouteEnter(to, from, next) {
    if (from.path !== "/MovieDetail") {
      next((vm) => {
        vm.$nextTick(() => {
          vm.initView(true)
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0
        })
      });
    } else {
      next();
    }
  },
  // //在页面离开时记录滚动位置
  // beforeRouteLeave(to, from, next) {
  //   this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  //   next()
  // },
  // //进入该页面时，用之前保存的滚动位置赋值
  // beforeRouteEnter(to, from, next) {
  //   console.log( ' -----------',this.scrollTop)
  //   next(vm => {
  //     document.body.scrollTop = vm.scrollTop
  //   })
  // },
  mounted() {


  },
}

</script>
<style lang="scss" scoped>
//自定义指示器
.indicators {
  position: absolute;
  bottom: 5px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);

  .indicator {
    width: 0.5rem;
    height: 0.5rem;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.6;
    transition: opacity 0.5s, background-color 0.5s;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &:last-child {
      margin-right: 4px;
    }

    &.active {
      width: 1.2rem;
      height: 0.5rem;
      border-radius: 0.25rem;
      opacity: 1;
    }
  }
}

.head {
  padding: .9rem 1%;
  height: 1.25rem;
  width: 98%;
  color: white;
  z-index: 2;
}

.backg {
  // background-image: url("../../assets/images/movieback.png");
  background-image: linear-gradient(top, #dd6351 0%, #fbe5da 100%);
  background-size: 100% 100%;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;

  background-color: black;
}

.more {

  border-radius: 1.5rem;
  padding: 0.8rem 0;
  width: 90%;
  margin-left: 5%;
  font-size: 1rem;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  color: #f5f5f5;
  text-align: center;
}

.empt {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.buttomBt {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  height: 3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  align-items: center;
}
</style>