<template>
  <div class="box">
    <div v-for="(item, index) in list" :key="index" class="p-l-10 font-s-14 bgc p-b-10 p-t-10" @click="goDetail(item)">
      <div class="flex  jcsb">
        <div style="display: flex;flex-direction: row;flex: auto;">
          <img class="img" :src="item.pic" />
          <div style="padding-left: 0.8rem;flex: auto;">
            <div style="align-items: center; display: flex;">
              <span class="textname" style="font-size: 1rem;"> {{ item.name }}</span>
              <div v-if="versionType(item.versionTypes) == 1" class="taggray"> <span> {{ item.versionTypes }}</span>
              </div>
              <div v-if="versionType(item.versionTypes) == 2" class="taggrayb"> <span
                  style="padding: 0 0.2rem;">IMAX</span> <span style="background-color: #DDD;padding: 0 0.2rem;">2D</span>
              </div>
              <div v-if="versionType(item.versionTypes) == 3" class="taggrayb"> <span
                  style="padding: 0 0.2rem;">IMAX</span> <span style="background-color: #DDD;padding: 0 0.2rem;">3D</span>
              </div>
            </div>
            <div style="margin-top: 0.5rem; height: 4rem;margin-right: 10px;">
              <div class="textcon">
                <div v-if="item.grade"> 评分 <span style="color:#FFB83E;font-weight: 600;"> {{ item.grade }}</span></div>
                <span v-else> 暂无评分</span>
              </div>
              <span class="textcon" style=" font-size: 0.85rem;">导演:{{ item.director }}</span>
              <span class="textcon" style=" font-size: 0.85rem;">主演: {{ item.cast }}</span>
            </div>
            <div style="display: flex;justify-content: flex-end;width: 100%; margin-top: 0.92rem;">
              <div v-if="canBuy(item)" class="buyvote" @click.stop="goCinema(item)">
                购票
              </div>
              <div v-else class="buyvote2">
                预售
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    shouTop: Boolean,

    list: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  components: {},
  methods: {
    canBuy(item) {
      let newDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      //计算相差多少天，参数可传：day 、second、minute
      let dateRange = this.$moment(newDate).diff(this.$moment(item.publishDate), 'second')
      return dateRange > 0
    },
    versionType(val) {
      let type = 1
      if (val == 'IMAX 2D') {
        type = 2
      } else if (val == 'IMAX 3D') {
        type = 3
      }
      return type
    },

    goDetail(val) {
      let type = this.canBuy ? 1 : 2
      this.$router.push({
        path: "/MovieDetail",
        query: {
          showbutton: type,
          item: JSON.stringify(val),
        },
      });
    },
    goCinema(val) {
      this.$router.push({
        path: "/Cinema",
        query: {
          filmId: val.filmId,
          movieName: val.name,
        },
      });
    },
  },
  mounted() {
    // console.log(this.list);

  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.box {
  .img {
    width: 6.85rem;
    height: 9.14rem;
    min-width: 6.85rem;
    min-height: 9.14rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .bgc {
    background-color: #fff;
    border-radius: 0.5rem;
    margin: 10px 5px;
  }

  .msg {
    margin-top: 20px;
    padding-top: 5px;
    border-top: 1px solid rgb(184, 182, 182);
  }

}

.buyvote {
  background-color: #D23B2E;
  color: #e8e8e8;
  padding: 0.3rem 0;
  width: 4.5rem;
  min-width: 4.5rem;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.buyvote2 {
  background-color: #3D9EE5;
  color: #e8e8e8;
  padding: 0.3rem 0;
  width: 4.5rem;
  min-width: 4.5rem;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 1rem;
  margin-right: 1rem;
}

.fen {

  width: 100%;
  padding-top: 0.2rem;
  opacity: 0.7;
  font-size: 0.8rem;

}

.textname {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin-top: 0.4rem;
  -webkit-box-orient: vertical;
}

.taggray {
  background-color: #DDD;
  padding: 0 0.2rem;
  border-radius: 0.2rem;
  margin-left: 0.2rem;
  margin-right: 0.3rem;
  margin-top: 0.5rem;
  white-space: nowrap;
  font-size: 0.8rem;
}

.taggrayb {
  border: 1px solid #DDD;

  border-radius: 0.2rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  margin-top: 0.5rem;
  white-space: nowrap;
  font-size: 0.6rem;
}

.top {
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  background-color: #FFB83E;
  color: #FFF;
  font-size: 0.8rem;
  height: 1.4rem;
  width: 3.3rem;
  padding-left: 0.2rem;
  line-height: 1.4rem;
  text-align: center;
  margin-right: -0.1rem;
}

.textcon {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #666;
  margin-top: 0.3rem;
}</style>