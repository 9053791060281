<template>
  <!-- 搜索时的页面 -->
  <div class=" mt">
    <!-- 搜索结果 -->
    <div class="height-100 ">
      <div v-if="movies.length > 0" style="overflow: scroll;height: 100vh;">
        <movit-item :list="movies" :shou-top=false>
        </movit-item>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import MovitItem from "./MovieItem.vue";
export default {
  name: "",
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      movies: [],
      form: {
        cityCode: ''
      },
      cardId: '',//福利卡id
      filmName: '',
    };
  },
  components: { MovitItem },
  methods: {
    // 点击关键字搜索
    seach(val) {
      if (!Boolean(val)) {
        Toast.fail("未输入关键字");
      } else {
        this.$api
          .queryHotMoviesByCity(this.cardId, this.form.cityCode, val)
          .then((res) => {
            this.loading = false
            this.movies = res.data
            if (res.code == 1) {
              this.$Toast(res.msg);
            }
          })
          .catch((err) => {
            this.loading = false
            this.$Toast(err.msg);
          });
      }
    },
  },
  mounted() {
    this.cardId = localStorage.getItem("cardId");
    this.form.cityCode = localStorage.getItem("cityCode");
  },
  computed: {},
  watch: {
  },
};
</script>

<style lang='scss' scoped>
.his {
  height: 20px;
  line-height: 20px;
  margin: 5px 10px;
  background-color: rgba(224, 221, 221, 0.5);
}

.mt {
  padding-top: 0.3rem;
}
.seach-img {
  width: 20%;
}
</style>